import React from "react";

const EComerceDetail = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div
      className="absolute inset-0 -top-32 flex items-center h-full justify-center bg-black bg-opacity-60 p-0"
      style={{ zIndex: 999 }}
    >
      <div className="bg-white p-8 rounded-lg w-[90%] h-[95%] max-w-3xl overflow-y-auto">
        <div className="flex items-center justify-center bg-yellow-300 rounded-t-lg">
          <p className="text-2xl md:text-4xl text-white font-semibold p-5">
            E-Commerce: Automated Customer Targeting
          </p>
        </div>

        <div className="py-8">
          <Section
            title="The Opportunity"
            content="Knowing and understanding your customer base is mandatory for a successful E-commerce business. It's important to know their preferences, budgets, and interests.

            Machine Learning (ML) can help segment your customer base, and even predict how much they'll spend on certain products."
          />

          <Section
            title="The Solution"
            content="We developed an ML system that classifies new customers into spend categories based on the data of previous customers. Existing customers can also be reclassified by the system.

            The automated classifying of the customer base allows marketers to understand the targets of their campaigns more quickly and with less effort. Also, understanding the spending habits of new and existing customers allows for more reliable financial analysis of different product lines or business units."
          />

          <Section
            title="The Work"
            content="The modeling process starts with an analysis of the data to get an understanding for its structure. During this time we also communicate with accountants to understand their usage of data-producing systems, and the processes they perform with the data to gain a feel for any anomalies or idiosyncrasies.

            After this we test a variety of models and optimize the best one. We then set up systems to maintain and improve the ML system over time."
          />
        </div>

        <button
          onClick={() => onClose()}
          className="absolute top-2 right-2 text-4xl hover:text-white bg-white hover:bg-orange-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const Section = ({ title, content }) => (
  <div className="pl-8">
    <p className="text-black text-xl md:text-3xl font-semibold py-4">{title}</p>
    <p className="text-gray-700 text-sm md:text-lg">{content}</p>
  </div>
);

export default EComerceDetail;
