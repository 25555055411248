import React from "react";
import "../css/FeaturedContent.css";
import homePageVideo from "../images/home_page_video.mp4";

function FeaturedContent() {
  return (
    <div className="relative w-full h-1/2 md:h-5/6 mb-1">
      <video
        className="absolute inset-0 w-full h-full object-cover bg-cover"
        autoPlay
        muted
        loop
      >
        <source src={homePageVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="flex justify-center items-center w-full h-full md:h-full bg-white bg-opacity-50 z-50">
        <h2 className="text-4xl md:text-8xl lg:text-9xl ti-font z-50 w-1/2 text-center" style={{ color: '#ff7300' }}>
        <p>Business</p>
        <p>+</p>
        <p>Technology</p> 
        </h2>
      </div>
    </div>
  );
}

export default FeaturedContent;
