import React, { useState, useEffect, useRef } from "react";
import logoImage from "../images/Prometheus_LOGO_full-05.png";
import ceoImage from "../images/ceo.png";
import WeDoCard from "./WeDoCard";
import axios from "axios";
import { baseUrl } from "./helper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";
import ReactCardFlip from "react-card-flip";
import { JackInTheBox } from "react-awesome-reveal";
import Swal from 'sweetalert2';

const Card = ({ jobtitle, fullname, imageUrl, description }) => {
  return (
    <div className="h-full flex justify-center items-center">
      <div className="group block text-center w-full max-w-xs lg:max-w-md">
        <div className="flex justify-center mb-5">
          <img
            src={baseUrl + "images/pictures/" + imageUrl}
            alt={fullname + " image"}
            className="w-80 h-60 rounded-2xl object-cover transition-all duration-500 border-2 border-solid border-transparent group-hover:border-orange-600 z-0"
          />
        </div>
        <h4 className="text-2xl text-gray-900 font-semibold mb-2 transition-all duration-500 group-hover:text-orange-600">
          {fullname}
        </h4>
        <span className="text-xl text-gray-500 block transition-all duration-500 group-hover:text-gray-900">
          {jobtitle}
        </span>
        <p className="text-sm text-gray-500 mt-2 transition-all duration-500 group-hover:text-gray-900 text-justify mb-7">
          {description}
        </p>
      </div>
    </div>
  );
};

const EthosCard = ({ title, description }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardHover = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <JackInTheBox>
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" className="md:h-5/6">
      <div className="w-40 md:w-60 lg:w-72 h-40 md:h-60 lg:h-72 p-4 rounded group bg-white">
        <div
          className="h-full w-full  border-8 border-orange-500 flex items-center justify-center mx-2 md:mx-5 lg:mx-10 group"
          onMouseEnter={handleCardHover}>
          <p className="text-lg md:text-2xl lg:text-2xl font-semibold text-black py-10">
            {title}
          </p>
        </div>
      </div>

      <div className="w-40 md:w-60 lg:w-72 h-40 md:h-60 lg:h-72 p-4 rounded group bg-white">
        <div
          className="h-full w-full border-2 bg-orange-500 flex items-center justify-center mx-2 md:mx-5 lg:mx-10 group "
          onMouseLeave={handleCardHover}>
          <p className=" text-xs md:text-sm font-semibold text-center text-white px-1 md:px-5 lg:px-5">
            {description}
          </p>
        </div>
      </div>
    </ReactCardFlip>
    </JackInTheBox>
  );
};

function WeAre() {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);
  const [capabilities, setCapabilities] = useState([]);
  const [ethos, setEthos] = useState([]);

  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.2 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  function getTeams() {
    axios
      .get(baseUrl + "api/v1/teams/")
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          let teamsLeaders = data.map((item) => [
            item.fullname,
            item.title,
            item.imagePath,
            item.bio,
          ]);
          setTeams(teamsLeaders);
        }
        console.log(response);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#ff7300",
          text: "Something went wrong!",
          confirmButtonColor: "#ff7300",
          footer: null,
        });
      });
  }

  function getEthos() {
    axios
      .get(baseUrl + "api/v1/items/?category=ethos")
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          let names = data.map((item) => [item.name, item.description]);
          setEthos(names);
        }
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#ff7300",
          text: "Something went wrong!",
          confirmButtonColor: "#ff7300",
          footer: null,
        });
      });
  }

  useEffect(() => {
    getTeams();
    getEthos();
  }, []);

  return (
    <>
      <div className=" h-full mb-20 md:h-screen md:mb-0 mx-2 items-center px-5 md:px-16 bg-white">
        <div className="flex items-center w-1/2 pt-14 pb-7 md:px-16" id="leadership">
          <h2 className="text-4xl text-orange-600 font-semibold mb-6 text-center heading-font">
            Leadership
          </h2>
        </div>
        <Swiper
          slidesPerView={5}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {teams.map((team, index) => (
            <SwiperSlide>
              <Card
                jobtitle={team[1]}
                fullname={team[0]}
                imageUrl={team[2]}
                description={team[3]}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        className={`transition-all duration-2000 h-full mb-5${
          isVisible ? "opacity-100 scale-100" : "opacity-0 scale-95"
        }`}
        ref={cardRef}
        id="ethos"
      >
        <div className="items-center pt-14 pb-7 mt-10 md:mb-10 lg:mb-10">
          <h2 className="text-4xl md:text-4xl lg:text-4xl text-orange-600 font-semibold mb-2 px-5 md:px-32 heading-font">
            Our Ethos
          </h2>
        </div>
        <div
          className={`grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 md:h-4/6 lg:h-4/6 justify-around md:mx-16 transition-all duration-2000`}
        >
          <JackInTheBox>
          {ethos.map((etho) => (
            <EthosCard title={etho[0]} description={etho[1]} />
          ))}
          </JackInTheBox>
        </div>
      </div>
    </>
  );
}

export default WeAre;
