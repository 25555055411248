import React from "react";
import { Link } from "react-router-dom";

function Motto() {
  return (
    <div className="flex justify-center pt-10 pb-20 mb-10">
      <div className="intro-content cc-homepage">
        <div className="intro-text">
          <div className="heading-jumbo">
            You Know the
            <span className="ml-2 bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.orange.600),theme(colors.orange.100),theme(colors.orange.400),theme(colors.orange.400),theme(colors.orange.400),theme(colors.orange.100),theme(colors.orange.400))] bg-[length:200%_auto] animate-gradient">
              Business
            </span>
            <span> </span>We Know the
            <span className="ml-2  bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.orange.600),theme(colors.orange.100),theme(colors.orange.400),theme(colors.orange.400),theme(colors.orange.400),theme(colors.orange.100),theme(colors.orange.400))] bg-[length:200%_auto] animate-gradient ">
              Tech
            </span>
          </div>
          <div className=" text-3xl md:text-5xl pt-10 mt-10 md:mt-20">
          <p>To learn how you can</p>
          innovate your practice
          <Link to="/contact-us"
                className="inline pl-2 text-orange-500 hover:text-orange-500"
                style={{ textDecoration: "none" }}>
                  contact us
                  </Link>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Motto;
