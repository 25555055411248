import React, { useState } from "react";
import axios from "axios";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { baseUrl } from "./helper";
import Swal from 'sweetalert2';

const ContactPage = () => {
  const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      company: '',
      jobTitle: '',
      email: '',
      phoneNumber: '',
      inquiryType: '',
      comments: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        const response = await axios.post(baseUrl+'api/v1/send-comment', formData);
        console.log(response.data);
        if (response.status === 200) {
        setLoading(false);
        setFormData(null)
        Swal.fire({
          icon: "success",
          iconColor: "#ff7300",
          title: "Thank you!",
          text: "Thank you! Your submission has been received!",
          confirmButtonColor: "#ff7300",
        })
      } else {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#ff7300",
          text: "Oops! Something went wrong while submitting the form.",
          confirmButtonColor: "#ff7300",
          footer: null,
        })
      }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#ff7300",
          text: "Oops! Something went wrong while submitting the form.",
          confirmButtonColor: "#ff7300",
          footer: null,
        })
      }
    };

    
  return (
    <>
    <div className="w-full min-h-full bg-orange-500 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 ">
      <div className="flex flex-col p-3 md:p-10 items-center">
        <h1 className="text-xl md:text-4xl lg:text-4xl font-bold mb-4 w-3/4 md:w-1/2 lg:w-1/2 heading-font">Learn More</h1>
        <p className="text-sm md:text-lg lg:text-xl w-3/4 md:w-1/2 lg:w-1/2 text-justify text-white">
          Do you have a question or need more info? Please enter your
          information and describe your inquiry, and we’ll get back to you as
          soon as possible. Thanks!
        </p>
      </div>

      <div className="flex flex-col items-center p-3 md:p-10 lg:p-10 bg-orange-500">
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-3 mb-1 md:mb-6 lg:mb-6">
            <div className="w-1/2 md:w-1/2 px-3 mb-2 md:mb-0">
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-2 md:py-3 lg:py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="first-name"
                name="firstName"
                type="text"
                placeholder="First Name"
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-1/2 md:w-1/2 px-3 mb-2">
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-2 md:py-3 lg:py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="last-name"
                name="lastName"
                type="text"
                placeholder="Last Name"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-1 md:mb-6 lg:mb-6">
            <div className="w-1/2 md:w-1/2 px-3 mb-2 md:mb-0">
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-2 md:py-3 lg:py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="company"
                name="company"
                type="text"
                placeholder="Company Name"
                onChange={handleChange}
              />
            </div>
            <div className="w-1/2 md:w-1/2 px-3">
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-2 md:py-3 lg:py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="job-title"
                name="jobTitle"
                type="text"
                placeholder="Job Title"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-1 md:mb-6 lg:mb-6">
            <div className="w-1/2 md:w-1/2 px-3 mb-2 md:mb-0">
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-2 md:py-3 lg:py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                onChange={handleChange}
                required
              />
            </div>
            <div className="w-1/2 md:w-1/2 px-3">
              <input
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-2 md:py-3 lg:py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="phone-number"
                name="phoneNumber"
                type="text"
                placeholder="Phone"
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
                  id="inquiry-type"
                  name="inquiryType"
                  defaultValue="Inquiry Type"
                  onChange={handleChange}
                  required
                >
                  <option disabled>Inquiry Type</option>
                  <option>App development</option>
                  <option>Automation</option>
                  <option>Cloud Infrastructure</option>
                  <option>Data Management</option>
                  <option >Digital Strategy</option>
                  <option>Machine Learning</option>
                  <option>Predictive Analytics</option>
                  <option>Tech Stack Management</option>
                  <option>Something Else</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <ChevronDownIcon
                    className="-mr-1 h-9 w-9 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <textarea
                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="comments"
                name="comments"
                placeholder="Please add details about your inquiry"
                rows="3"
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mt-6 justify-center w-full">
            <div className="px-3">
              <button
                className=" ti-button bg-none hover:bg-orange-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit" disabled={loading}
              >
                 {loading ? (
                       <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-orange-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                       <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                   </svg>
                ) : (
                  "Submit"
                )}
              </button>

            </div>
          </div>
        </form>
      </div>
    </div>
    <hr></hr>
    </>
  );
};

export default ContactPage;
