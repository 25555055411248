import React from "react";
import TwoCardRow from "./TwoCardRow";
import PromituseCard from "./PromitusCard";

function WeServe() {
  return (
    <>
      <PromituseCard />
      <TwoCardRow />
    </>
  );
}

export default WeServe;
