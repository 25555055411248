import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logoImage from "../images/Prometheus_LOGO_full-05.png";
import { HashLink } from 'react-router-hash-link';
export default function Navbar() {
  const [activeItem, setActiveItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = (item) => {
    setActiveItem(item);
  };

  const handleMouseLeave = () => {
    setActiveItem(null);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar-container bg-white" style={{  zIndex: 999}}>
      <div className="py-3" onMouseEnter={() => handleMouseLeave}>
        <div className="flex items-center justify-between py-0 my-0 px-4">
          <Link to="/" className="flex items-center space-x-6 mx-10">
            <img
              src={logoImage}
              alt="Logo"
              className=" h-9 w-32 md:w-40 md:h-12 "
            />
          </Link>
          <nav className={`hidden md:flex space-x-0 relative`}>
          <div className="relative group mx-0">
              <NavLink
                to="/"
                onClick={handleMouseLeave}
                onMouseEnter={handleMouseLeave}
                className={({ isActive }) =>
                  [
                    isActive ? "text-span px-4 ti-button" : "",
                    "hover:text-orange-500 px-4 mx-0 ti-button group",
                  ].join(" ")
                }
                style={{ textDecoration: "none" }}
              >
                Home
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-orange-300"></span>
              </NavLink>
            </div>
            <div className="relative group mx-0">
              <NavLink
                to="/weserve"
                onMouseEnter={() => handleMouseEnter("serve")}
                onClick={handleMouseLeave}
                className={({ isActive }) =>
                  [
                    isActive ? "text-span px-4 ti-button" : "",
                    "hover:text-orange-500 px-4 mx-0 ti-button group",
                  ].join(" ")
                }
                style={{ textDecoration: "none" }}
              >
                Who We Serve
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-orange-300"></span>
              </NavLink>
            </div>
            <div className="relative group mx-0">
              <NavLink
                to="/wedo"
                onMouseEnter={() => handleMouseEnter("do")}
                onClick={handleMouseLeave}
                className={({ isActive }) =>
                  [
                    isActive ? "text-span px-4 ti-button" : "",
                    "hover:text-orange-500 px-4 mx-0 ti-button group",
                  ].join(" ")
                }
                style={{ textDecoration: "none" }}
              >
                What We Do
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-orange-300"></span>
              </NavLink>
            </div>
            <div className="relative group">
              <NavLink
                to="/weare"
                onMouseEnter={() => handleMouseEnter("are")}
                onClick={handleMouseLeave}
                className={({ isActive }) =>
                  [
                    isActive ? "text-span px-4 ti-button" : "",
                    "hover:text-orange-500 px-4 ti-button group",
                  ].join(" ")
                }
                style={{ textDecoration: "none" }}
              >
                Who We Are
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-orange-300"></span>
              </NavLink>
            </div>
          </nav>
          <div>
            <div className="relative">
              <NavLink
                to="/contact-us"
                className=" contact-button hidden mr-10 md:block lg:block"
                style={{ textDecoration: "none" }}
              >
                Contact Us
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-orange-300"></span>
              </NavLink>
            </div>
          </div>
          <div className="md:hidden flex h-screen md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse absolute right-0 top-3">
            <div
              className={`text-base list-none bg-white rounded-lg shadow transition-all duration-500 transform ${
                isOpen
                  ? "opacity-100 translate-x-0"
                  : "opacity-0 translate-x-10"
              }`}
              id="user-dropdown"
            >
              <button
                data-collapse-toggle="navbar-user"
                type="button"
                className={`inline-flex items-center p-2 mb-6 -mt-7 w-10 h-10 justify-center text-sm text-black rounded-lg md:hidden hover:bg-gray-200 no-underline focus:outline-none focus:ring-2 focus:ring-gray-300
      ${isOpen ? "inline" : "hidden"}`}
                aria-controls="navbar-user"
                aria-expanded="false"
                onClick={handleToggle}
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={handleToggle}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <ul className={`py-2 border-0 ${isOpen ? "inline" : "hidden"}`}>
                <li className="list-none">
                  <NavLink
                    to="/weserve"
                    className="block px-4 py-2 text-lg text-black hover:bg-gray-200 no-underline focus:bg-orange-500"
                    onClick={handleToggle}
                  >
                    Who We Serve
                  </NavLink>
                </li>
                <li className="list-none">
                  <NavLink
                    to="/wedo"
                    className="block px-4 py-2 text-lg text-black hover:bg-gray-200 no-underline focus:bg-orange-500"
                    onClick={handleToggle}
                  >
                    What We Do
                  </NavLink>
                </li>
                <li className="list-none">
                  <NavLink
                    to="/weare"
                    className="block px-4 py-2 text-lg text-black hover:bg-gray-200 no-underline focus:bg-orange-500"
                    onClick={handleToggle}
                  >
                    Who We Are
                  </NavLink>
                </li>
                <li className="list-none">
                  <NavLink
                    to="/contact-us"
                    className="block px-4 py-2 text-lg text-black hover:bg-gray-200 no-underline focus:bg-orange-500"
                    onClick={handleToggle}
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
            <button
              data-collapse-toggle="navbar-user"
              type="button"
              className={`inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-black rounded-lg md:hidden no-underline focus:outline-none focus:ring-2 focus:ring-gray-300
    ${isOpen ? "hidden" : "inline"}`}
              aria-controls="navbar-user"
              aria-expanded="false"
              onClick={handleToggle}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
                onClick={handleToggle}
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
        </div>

        {activeItem && (
          <div
            className="space-y-0 py-5 my-0 absolute w-full h-80 bg-white transform transition-all duration-500 ease-in-out"
            onMouseLeave={handleMouseLeave}
            style={{  zIndex: 1000}}
          >
            {activeItem === "serve" && (
              <>
                <ul className="ul-class-name flex justify-left space-x-12 list-none">
                <li className="w-48 hover:text-orange-500">
                  <HashLink smooth to="/weserve#casestudies" className="hover:text-orange-500" onClick={handleMouseLeave} style={{ textDecoration: "none" }}> 
                    <p
                      className="flex justify-left font-semibold space-x-12"
                      style={{ "font-size": "1.2em" }}
                    >
                      Case Studies
                    </p>
                    </HashLink>
                  </li>
                  <li className="w-52">
                  <HashLink smooth to="/weserve#industries&techneeds" className="hover:text-orange-500" onClick={handleMouseLeave} style={{ textDecoration: "none" }}> 
                    <p
                      className="flex justify-left font-semibold space-x-12 "
                      style={{ "font-size": "1.2em" }}
                    >
                      Industries
                    </p>
                    </HashLink>
                    <a style={{ textDecoration: "none" }}>
                      <span className="block hover:text-orange-500">Automotive</span>
                      <span className="block hover:text-orange-500">Construction</span>
                      <span className="block hover:text-orange-500">E-Commerce</span>
                      <span className="block hover:text-orange-500">Finance</span>
                      <span className="block hover:text-orange-500">Manufacturing</span>
                    </a>
                  </li>
                  <li className="w-48">
                  <HashLink smooth to="/weserve#industries&techneeds" className="hover:text-orange-500" onClick={handleMouseLeave} style={{ textDecoration: "none" }}> 
                    <p
                      className="flex justify-left font-semibold space-x-12"
                      style={{ "font-size": "1.2em" }}
                    >
                      Tech Needs
                    </p>
                    </HashLink>
                    <a style={{ textDecoration: "none" }}>
                      <span className="block hover:text-orange-500">Third Party Software Integration</span>
                      <span className="block hover:text-orange-500">In House Development</span>
                    </a>
                  </li>
                </ul>
              </>
            )}
            {activeItem === "do" && (
              <ul className="ul-class-name flex justify-left space-x-12 list-none">
                <li className="w-48 hover:text-orange-500">
                <HashLink smooth to="/wedo#capabilities" className="hover:text-orange-500" onClick={handleMouseLeave} style={{ textDecoration: "none" }}> 

                  <p
                    className="flex justify-left text-center font-semibold"
                    style={{ "font-size": "1.2em" }}
                  >
                    Capabilities
                  </p>
                  </HashLink>
                  <a style={{ textDecoration: "none" }}>
                    <span className="block hover:text-orange-500">App Development</span>
                    <span className="block hover:text-orange-500">Automation</span>
                    <span className="block hover:text-orange-500">Cloud Infrastructure</span>
                    <span className="block hover:text-orange-500">Data Management</span>
                    <span className="block hover:text-orange-500">Digital Strategy</span>
                    <span className="block hover:text-orange-500">Machine Learning</span>
                    <span className="block hover:text-orange-500">Predictive Analytics</span>
                    <span className="block hover:text-orange-500">Tech Stack Management</span>
                  </a>
                </li>
                <li className="w-48 hover:text-orange-500">
                <HashLink smooth to="/wedo#methodology" className="hover:text-orange-500" onClick={handleMouseLeave} style={{ textDecoration: "none" }}> 

                  <p
                    className="flex justify-left text-center font-semibold"
                    style={{ "font-size": "1.2em" }}
                  >
                    Methodology
                  </p>
                  </HashLink>
                </li>
              </ul>
            )}
            {activeItem === "are" && (
              <ul class="ul-class-name flex justify-left space-x-12 list-none">
                <li class="relative w-48 hover:text-orange-500 cursor-pointer">
                <HashLink smooth to="/weare#leadership" className="hover:text-orange-500" onClick={handleMouseLeave} style={{ textDecoration: "none" }}> 

                  <p
                    class="flex justify-left text-center font-semibold"
                    style={{ "font-size": "1.2em" }}
                  >
                    Leadership
                  </p>
                  </HashLink>
                  <div class="absolute top-1/2 right-0 transform -translate-y-1/2 hidden group-hover:block">
                    <div class="w-0 h-0 border-t-3 border-r-3 border-transparent border-solid border-black"></div>
                  </div>
                </li>
                <li class="relative w-48 hover:text-orange-500 cursor-pointer">
                <HashLink smooth to="/weare#ethos" className="hover:text-orange-500" onClick={handleMouseLeave} style={{ textDecoration: "none" }}> 

                  <p
                    class="flex justify-left text-center font-semibold group"
                    style={{ "font-size": "1.2em" }}
                  >
                    Our Ethos
                  </p>
                  </HashLink>
                  <div class="absolute top-1/2 right-0 transform -translate-y-1/2 hidden group-hover:block">
                    <div class="w-0 h-0 border-t-3 border-r-3 border-transparent border-solid border-black"></div>
                  </div>
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
