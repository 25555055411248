import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import "./css/normalize.css";
import "./css/webflow.css";
import "./css/maliks-radical-site-5ef75a.webflow.css";
import WeAre from "./components/WeAre";
import WeServe from "./components/WeServe";
import WeDo from "./components/WeDo";
import HomePage from "./components/HomePage";
import ContactPage from "./components/ContactPage";

function App() {
  return (
    <Router>
      <div className="App scroll-smooth h-screen m-0 p-0">
        <Navbar />
        <Routes>
          <Route path="/wedo" element={<WeDo />} />
          <Route path="/*" element={<HomePage />} />
          <Route path="/weserve" element={<WeServe />} />
          <Route path="/weare" element={<WeAre />} />
          <Route path="/contact-us" element={<ContactPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
