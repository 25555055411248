import React, { useEffect, useState } from "react";
import { baseUrl } from "./helper";
import axios from "axios";
import Swal from "sweetalert2";
import weDoVideo from "../images/wedo_video.mp4";
import { Fade } from "react-awesome-reveal";

const Card = ({ title, description }) => {
  return (
    <div className="flex items-center justify-center sm:w-1/2 md:w-1/4">
      <div className="grid w-44 md:w-72 h-28 md:h-48 lg:h-48 p-4 rounded group bg-white justify-center items-center group cursor-help">
        <p className="text-xl group-hover:text-orange-500 md:text-4xl text-center font-semibold transition duration-300 transform hover:scale-105">
          {title}
        </p>
        <p className="text-center hidden group-hover:md:block pt-3 text-lg transition ease-in-out delay-150 group-hover:-translate-y-1 duration-700">
          {description}
        </p>
      </div>
    </div>
  );
};

function WeDo() {
  const [capabilities, setCapabilities] = useState([]);

  function getCapabilities() {
    axios
      .get(baseUrl + "api/v1/items/?category=capabilities")
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          let names = data.map((item) => [item.name, item.description]);
          setCapabilities(names);
        }
        console.log(response);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#ff7300",
          text: "Something went wrong!",
          confirmButtonColor: "#ff7300",
          footer: null,
        });
      });
  }

  useEffect(() => {
    getCapabilities();
  }, []);

  return (
    <>
      <div className="relative w-full h-1/2 md:h-5/6 mb-5">
        <video
          className="absolute inset-0 w-full h-full object-cover bg-cover"
          autoPlay
          muted
          loop
        >
          <source src={weDoVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div id="capabilities">
        <h2 className="group text-3xl md:text-4xl text-orange-600 font-semibold mb-2 my-10 heading-font px-5 md:px-16">
          Capabilities
        </h2>
        <div className="flex flex-wrap justify-center gap-4">
          {capabilities.map((item) => (
            <Card key={item[0]} title={item[0]} description={item[1]} />
          ))}
        </div>
      </div>
      <div
        className={`p-5 md:px-20 items-center md:py-20 transition duration-300 ease-in`}
        id="methodology"
      >
        <h2 className="group text-3xl md:text-4xl text-orange-600 font-semibold mb-2 my-10 heading-font">
          Methodology
        </h2>
        <Fade cascade damping={0.2}>
          <p className="text-lg md:text-3xl pt-5 md:pt-10">
            Many times technical work fails due to two factors:
          </p>
          <ol className="text-lg md:text-3xl">
            <li>
              1. business people don't understand the capabilities of technology
            </li>
            <li>
              2. tech people don't understand the details of business or
              industry
            </li>
          </ol>

          <p className="text-lg md:text-3xl pt-5 md:pt-10">
            Our methodology focuses on having a symbiotic system between our
            business professionals and technical experts. This way we can
            understand the material business impact as well as the latest and
            greatest technology in order to solve problems.
          </p>
        </Fade>
      </div>
    </>
  );
}

export default WeDo;
