import React, { useState, useEffect, useRef } from "react";
import { Slide } from "react-awesome-reveal";
import FinanceDetail from "./FinanceDetail";
import ManufacturingDetail from "./ManufacturingDetail";
import EComerceDetail from "./EComerceDetail";
const Card = ({
  bgColor,
  textColor,
  title,
  setShowModalHandle,
  modal1,
  modal2,
  modal3,
}) => {
  return (
    <div className="group">
      <Slide className=" group w-full h-full flex justify-around px-10 py-4 md:p-10">
        <div
          className={`${bgColor} brightness-120 shadow-md p-4 flex-1 w-5/6 md:h-4/6 hover:scale-110 transform transition duration-300 ease-in-out animate-spince animate-ease-in animate-fill-forwards
      flex flex-col justify-center items-center`}
        >
          <h2
            className={`text-2xl md:text-4xl font-semibold mb-2 text-center px-5 ${textColor}`}
          >
            {title}
          </h2>
          <div className="flex items-center justify-center">
            <button
              onClick={() => setShowModalHandle()}
              className=" md:invisible group-hover:visible text-white focus:outline-none w-20 h-5 text-lg hover:underline"
            >
              See More
            </button>
          </div>
        </div>
      </Slide>
      <FinanceDetail isOpen={modal1} onClose={setShowModalHandle} />
      <ManufacturingDetail isOpen={modal2} onClose={setShowModalHandle} />
      <EComerceDetail isOpen={modal3} onClose={setShowModalHandle} />
    </div>
  );
};

const PromituseCard = () => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.2 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const setShowModal1Evnt = () => {
    setShowModal1(!showModal1);
  };
  const setShowModal2Evnt = () => {
    setShowModal2(!showModal2);
  };
  const setShowModal3Evnt = () => {
    setShowModal3(!showModal3);
  };

  return (
    <>
      <div className="flex items-center pt-16 px-10 " id="casestudies">
        <h2 className="text-2xl md:text-4xl text-orange-600 font-semibold text-center heading-font">
          Case Studies
        </h2>
      </div>
      <div
        ref={cardRef}
        className={`grid grid-cols-1 justify-items-center md:grid-cols-3 md:h-screen h-full bg-white w-full sapce-x-5 transition-all duration-2000 ${
          isVisible ? "opacity-100 scale-100" : "opacity-0 scale-90"
        }`}
      >
        <Card
          bgColor="bg-orange-700"
          title="Finance: 
          AR Forecast"
          textColor={"text-white"}
          setShowModalHandle={setShowModal1Evnt}
          modal1={showModal1}
        />
        <Card
          bgColor="bg-yellow-600"
          title="Manufacturing:
          Product
          Maintenance
          Forecast"
          textColor={"text-white"}
          setShowModalHandle={setShowModal2Evnt}
          modal2={showModal2}
        />
        <Card
          bgColor="bg-yellow-300"
          title="E-Commerce: Automated Customer Targeting"
          textColor={"text-white"}
          setShowModalHandle={setShowModal3Evnt}
          modal3={showModal3}
        />
      </div>
    </>
  );
};

export default PromituseCard;
