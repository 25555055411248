import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { baseUrl } from "./helper";
import Swal from "sweetalert2";

const Card = ({ title, items, isSecondCard }) => {
  const [hoveredItem, setHoveredItem] = useState(-1);

  const handleMouseEnter = (index) => setHoveredItem(index);
  const handleMouseLeave = () => setHoveredItem(-1);

  return (
    <div
      className={`flex-1 mr-4 transform transition-all duration-600 ease-in-out z-0 hover:z-50 mb-10 ${
        hoveredItem !== -1
          ? "w-screen transform transition duration-300 ease-in-out relative"
          : "w-full"
      } ${isSecondCard && hoveredItem !== -1 ? "md:translate-x-[-50%]" : ""}`}
    >
      <div
        className="flex flex-col sm:flex-row card bg-black rounded-lg shadow-md p-4 flex-1 md:mx-16 lg:mx-16 h-auto md:h-5/6 group"
        onMouseLeave={handleMouseLeave}
      >
        {hoveredItem !== -1 && isSecondCard &&(
          <div className="relative w-full h-full md:h-full border-4 border-orange-500">
            <img
              src={baseUrl + "images/items/" + items[hoveredItem][1]}
              alt={title}
              className={"absolute inset-0 w-full h-full object-cover bg-cover"}
            />
            <div className="flex justify-center items-center w-full h-72 md:h-full z-50">
              <p className="flex items-center mx-auto text-xl md:text-5xl z-50 text-center text-white p-5 md:p-10 m-0 bg-orange-500 bg-opacity-50 h-full">
                {items[hoveredItem][2]}
              </p>
            </div>
          </div>
        )}
        <div className="card-content w-full z-0 hover:z-50 over">
          <h2 className=" text-2xl lg:text-4xl md:text-4xl text-orange-600 font-semibold mb-2 text-center heading-font">
            {title}
          </h2>
          <ul className=" text-xl md:text-4xl lg:text-4xl text-white leading-tight px-12 my-10 list-none">
            {items.map((item, index) => (
              <li
                key={index}
                className="flex justify-between hover-item group/edit group-hover:flex-row group-hover:w-full flex-row"
              >
                <a 
                  className="text-lg md:text-4xl lg:text-4xl text-white leading-tight no-underline group-hover:no-underline group-hover:text-white"
                  onMouseEnter={() => handleMouseEnter(index)}
                >
                  {item[0]}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {hoveredItem !== -1 && !isSecondCard && (
          <div className="relative w-full h-full md:h-full border-4 border-orange-500">
            <img
              src={baseUrl + "images/items/" + items[hoveredItem][1]}
              alt={title}
              className={"absolute inset-0 w-full h-full object-cover bg-cover"}
            />
            <div className="flex justify-center items-center w-full h-72 md:h-full z-50">
              <p className="flex items-center mx-auto text-xl md:text-5xl z-50 text-center text-white p-5 md:p-10 m-0 bg-orange-500 bg-opacity-50 h-full">
                {items[hoveredItem][2]}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const TwoCardRow = () => {
  const [hoveredItem, setHoveredItem] = useState(-1);
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);
  const [techNeeds, setTechNeeds] = useState([]);
  const [industries, setIndustries] = useState([]);

  const handleMouseEnter = (index) => setHoveredItem(index);
  const handleMouseLeave = () => setHoveredItem(-1);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.2 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [cardRef.current]);

  function getIndustries() {
    axios
      .get(baseUrl + "api/v1/items/?category=industries")
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          let industriesData = data.map((item) => [
            item.name,
            item.imagePath,
            item.description,
          ]);
          setIndustries(industriesData);
        }
        console.log(response);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#ff7300",
          text: "Something went wrong!",
          confirmButtonColor: "#ff7300",
          footer: null,
        });
      });
  }

  function getTechNeeds() {
    axios
      .get(baseUrl + "api/v1/items/?category=techneeds")
      .then(function (response) {
        if (response.status === 200) {
          let data = response.data;
          let techNeedsData = data.map((item) => [
            item.name,
            item.imagePath,
            item.description,
          ]);
          setTechNeeds(techNeedsData);
        }
        console.log(response);
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#ff7300",
          text: "Something went wrong!",
          confirmButtonColor: "#ff7300",
          footer: null,
        });
      });
  }
  console.log(industries);
  console.log(techNeeds);

  useEffect(() => {
    getIndustries();
    getTechNeeds();
  }, []);

  return (
    <div
      ref={cardRef}
      className={`grid grid-cols-1 md:grid-cols-2 mx-3 transition-all duration-200 md:h-5/6 lg:h-5/6 overflow-hidden ${
        isVisible ? "opacity-100 scale-100" : "opacity-0 scale-90"
      } animate-fadeIn`}
      id="industries&techneeds"
    >
      <Card
        title="Industries"
        items={industries}
        hoveredItem={hoveredItem}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      />
      <Card
        title="Tech Needs"
        items={techNeeds}
        hoveredItem={hoveredItem}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        isSecondCard={true}
      />
    </div>
  );
};

export default TwoCardRow;
