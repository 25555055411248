import React from "react";

const FinanceDetail = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="absolute inset-0 -top-32 flex items-center h-full justify-center bg-black bg-opacity-50 p-0"
      style={{ zIndex: 999 }}
    >
      <div className="bg-white p-8 rounded-lg w-[90%] h-[95%] max-w-3xl overflow-y-auto">
        <div className="flex items-center justify-center bg-orange-600 rounded-t-lg">
          <p className="text-2xl md:text-4xl text-white font-semibold p-5">
            Finance: AR Forecast
          </p>
        </div>
        <div className="py-8">
          <Section
            title="The Opportunity"
            content="Accounts Receivable (AR) forecasting is an essential process for assessing your company's finances. Most times, accountants look over past cash inflows of a client or product line to forecast future inflows.

            Machine Learning (ML), a subset of Artificial Intelligence is very good at this kind of task: taking in representative data and forecasting a new value based on current circumstances."
          />

          <Section
            title="The Solution"
            content="We transformed the AR forecasting process into an automated system. Using data pipelines from payable accounts and an ML model allows for more frequent updates to the forecast, which are significantly more accurate than the traditional method.

            All the  while saving accountants time so that they can focus their efforts on higher leverage activities."
          />

          <Section
            title="The Work"
            content="The modeling process starts with an analysis of the data to get an understanding for its structure. During this time we also communicate with accountants to understand their usage of data-producing systems, and the processes they perform with the data to gain a feel for any anomalies or idiosyncrasies.

            After this we test a variety of models and optimize the best one. We then set up systems to maintain and improve the ML system over time."
          />
        </div>

        <button
          onClick={() => onClose()}
          className="absolute top-2 right-2 text-4xl hover:text-white bg-white hover:bg-orange-600"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-7 w-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const Section = ({ title, content }) => (
  <div className="pl-8">
    <p className="text-black text-xl md:text-3xl font-semibold py-4">{title}</p>
    <p className="text-gray-700 text-sm md:text-lg">{content}</p>
  </div>
);

export default FinanceDetail;
