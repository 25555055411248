import React from "react";
import FeaturedContent from "./FeaturedContent";
import Motto from "./Motto";

function HomePage() {
  return (
    <>
      <FeaturedContent />
      <div className="flex flex-col p-5 md:p-16 items-center bg-orange-500">
        <p className="text-xl md:text-4xl lg:text-4xl text-white w-full md:w-5/6 lg:w-5/6 text-center tracking-wide px-2 md:px-5">
        The Prometheus Collaborative was created with the goal of sharing the capabilities of modern and future computer technology to assist 
        companies achieve their goals in a swiftly changing world.
        </p>
      </div>
      <Motto />
      
    </>
  );
}

export default HomePage;
